<template>
  <b-row>
    <b-col xl="8">
      <b-button size="sm" variant="success" @click.prevent="addNewCourier()" class="mb-2">Добавить</b-button>
      <b-table
          :items="couriers"
          :fields="fields"
          :tbody-tr-class="rowClass"
          stacked="md"
          show-empty
          small
          headVariant="dark"
          fixed
          bordered
      >

        <template #table-colgroup="scope">
          <col
              v-for="field in scope.fields"
              :key="field.key"
              :style="getWidthCol(field.key)"
          >
        </template>
        <template #cell(indx)="{index}">
          {{index+1}}
        </template>
        <template #cell(name)="{item}">
          <b-form-input v-model="item.name" placeholder="имя" @focusout="courierUpdate(item)"></b-form-input>
        </template>
        <template #cell(number)="{item}">
          <b-form-input v-model="item.number" placeholder="телефон" @focusout="courierUpdate(item)"></b-form-input>
        </template>
        <template #cell(tgChatId)="{item}">
          <b-form-input v-model="item.tgChatId" placeholder="id тг чата" @focusout="courierUpdate(item)"></b-form-input>
        </template>
        <template #cell(city)="{item}">
          <b-form-group v-slot="{ ariaDescribedby }">
            <b-form-radio-group
                id="btn-radios-2"
                v-model="item.city"
                :options="[{text: 'СПб', value: 'spb'},{text: 'Мск', value: 'msk'}]"
                :aria-describedby="ariaDescribedby"
                name="outline-primary"
                button-variant="outline-primary"
                size="sm"
                buttons
                @change="courierUpdate(item)"
            ></b-form-radio-group>
          </b-form-group>
<!--          <b-form-input v-model="item.city" placeholder="город" disabled></b-form-input>-->
        </template>
        <template #cell(status)="{item}">
          <b-form-group v-slot="{ ariaDescribedby }">
            <b-form-radio-group
                id="btn-radios-1"
                v-model="item.status"
                :options="[{text: 'вкл', value: 'ready'},{text: 'откл', value: 'notready'}]"
                :aria-describedby="ariaDescribedby"
                name="outline-primary"
                button-variant="outline-primary"
                size="sm"
                buttons
                @change="courierUpdate(item)"
                :disabled="!item.tgChatId"
            ></b-form-radio-group>
          </b-form-group>
        </template>

        <template #cell(actions)="{item}">
          <b-badge href="#" size="sm" variant="danger" @click.prevent="delCourier(item)" class="mr-2"><b-icon-trash /> удалить</b-badge>
        </template>
      </b-table>
    </b-col>
  </b-row>
</template>

<script>

export default {
  name: "wbviews-courier-list",
  props: [],
  data: () => ({
    isLoading: true,
    couriers: [],
    newCourier: {name:'', number: '', tgChatId: '', city: '', status: ''},
    fields: [
      { key: 'indx', label: '#', sortable: false, sortDirection: 'desc' },
      { key: 'name', label: 'Имя', sortable: false, sortDirection: 'desc' },
      { key: 'number', label: 'Телефон', sortable: false, sortDirection: 'desc' },
      { key: 'tgChatId', label: 'Id тг чата', sortable: false, sortDirection: 'desc' },
      { key: 'city', label: 'Город', sortable: true, sortDirection: 'desc' },
      { key: 'status', label: 'Статус', sortable: true, sortDirection: 'desc' },
      { key: 'actions', label: 'Действия', sortable: false, sortDirection: 'desc' },
    ],
  }),
  methods: {
    getWidthCol(key) {
      if (key=='card') return {width: '250px', minWidth: '250px'}
      if (key=='balance') return {width: '100px'}
      if (key=='bank') return {width: '150px'}
      if (key=='people') return {width: '130px'}
      if (key=='status') return {width: '150px'}
      if (key=='userId') return {width: '200px'}
      if (key=='actions') return {width: '80px'}
      if (key=='indx') return {width: '20px'}

    },
    alertMsg(title, message, style, append = false) {
      this.$bvToast.toast(`${message}`, {
        title: `${title}`,
        toaster: 'b-toaster-top-right',
        variant: style,
        solid: true,
        appendToast: append
      })
    },
    getCouriers() {
      fetch(`${process.env.VUE_APP_SERVER_API}/wbviews/couriers`, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {'Content-Type': 'application/json', 'dildo-token': this.$cookies.get("dildo-token")}
      })
          .then(resp => resp.json())
          .then(resp => {
            if (resp.status) this.couriers=resp.items
            if (!resp.status) this.alertMsg('Ошибка загрузки курьеров', `Ошибка сервера, попробуйте ещё раз ${resp.message}`, 'danger')
          })
    },
    addNewCourier() {
      fetch(`${process.env.VUE_APP_SERVER_API}/wbviews/getNewId`, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {'Content-Type': 'application/json', 'dildo-token': this.$cookies.get("dildo-token")}
      })
          .then(resp => resp.json())
          .then(resp => {
            if (resp.status) this.couriers.unshift({_id: resp.message, name:'', number: '', tgChatId: '', city: 'spb', status: 'notready'})
            if (!resp.status) this.alertMsg('Ошибка', `Не удалось получить id для новой записи ${resp.message}`, 'danger')
          })

    },
    delCourier(card) {
      fetch(`${process.env.VUE_APP_SERVER_API}/wbviews/cards`, {
        method: 'DELETE',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {'Content-Type': 'application/json', 'dildo-token': this.$cookies.get("dildo-token")},
        body: JSON.stringify(card)
      })
          .then(resp => resp.json())
          .then(resp => {
            if (resp.status) {
              this.alertMsg('Удаление карты | Успешно', `удалена с id: ${resp.message}`, 'success')
              this.cards=this.cards.filter(e=>e._id!=card._id)
            }
            if (!resp.status) this.alertMsg('Ошибка', `Не удалось удалить карту ${resp.message}`, 'danger')
          })

    },
    courierUpdate(card) {
      if (this.validationCard(card)) return false
      fetch(`${process.env.VUE_APP_SERVER_API}/wbviews/couriers`, {
        method: 'PUT',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {'Content-Type': 'application/json', 'dildo-token': this.$cookies.get("dildo-token")},
        body: JSON.stringify(card)
      })
          .then(resp => resp.json())
          .then(resp => {
            if (resp.status) {
              this.couriers.find(e=>e._id==card._id)._id==resp.message
              // this.alertMsg('Обновление карты | Успешно', `обновлёна с id: ${resp.message}`, 'success')
            }
            if (!resp.status) {
              console.log(resp)
              this.alertMsg('Обновление курьеров | Ошибка', `ошибка сервера ${resp.message}`, 'danger')
            }
          })

      // this.alertMsg('Ошибка обновления', `Ошибка сервера, попробуйте ещё раз`, 'danger')
    },
    validationCard(item) {
      if (item) return item.name.length<3 || item.number.length<10
    },
    rowClass(item, type) {
      if (!item || type !== 'row') return
      if (this.validationCard(item)) return 'table-danger'
      // return 'table-success'
    },
  },
  components: {},
  async mounted() {
    const dildoToken = this.$cookies.get("dildo-token");
    if (!dildoToken) this.$router.push({path: "/login"});
    if (dildoToken) {
      this.getCouriers()
    }

  },
  computed: {

  }
};
</script>

<style>
input, textarea, div {
  font-size: 10px!important;
}
.form-group {
  margin-bottom: 0;
}
</style>