var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-row',[_c('b-col',{attrs:{"xl":"8"}},[_c('b-button',{staticClass:"mb-2",attrs:{"size":"sm","variant":"success"},on:{"click":function($event){$event.preventDefault();return _vm.addNewCourier()}}},[_vm._v("Добавить")]),_c('b-table',{attrs:{"items":_vm.couriers,"fields":_vm.fields,"tbody-tr-class":_vm.rowClass,"stacked":"md","show-empty":"","small":"","headVariant":"dark","fixed":"","bordered":""},scopedSlots:_vm._u([{key:"table-colgroup",fn:function(scope){return _vm._l((scope.fields),function(field){return _c('col',{key:field.key,style:(_vm.getWidthCol(field.key))})})}},{key:"cell(indx)",fn:function(ref){
var index = ref.index;
return [_vm._v(" "+_vm._s(index+1)+" ")]}},{key:"cell(name)",fn:function(ref){
var item = ref.item;
return [_c('b-form-input',{attrs:{"placeholder":"имя"},on:{"focusout":function($event){return _vm.courierUpdate(item)}},model:{value:(item.name),callback:function ($$v) {_vm.$set(item, "name", $$v)},expression:"item.name"}})]}},{key:"cell(number)",fn:function(ref){
var item = ref.item;
return [_c('b-form-input',{attrs:{"placeholder":"телефон"},on:{"focusout":function($event){return _vm.courierUpdate(item)}},model:{value:(item.number),callback:function ($$v) {_vm.$set(item, "number", $$v)},expression:"item.number"}})]}},{key:"cell(tgChatId)",fn:function(ref){
var item = ref.item;
return [_c('b-form-input',{attrs:{"placeholder":"id тг чата"},on:{"focusout":function($event){return _vm.courierUpdate(item)}},model:{value:(item.tgChatId),callback:function ($$v) {_vm.$set(item, "tgChatId", $$v)},expression:"item.tgChatId"}})]}},{key:"cell(city)",fn:function(ref){
var item = ref.item;
return [_c('b-form-group',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ariaDescribedby = ref.ariaDescribedby;
return [_c('b-form-radio-group',{attrs:{"id":"btn-radios-2","options":[{text: 'СПб', value: 'spb'},{text: 'Мск', value: 'msk'}],"aria-describedby":ariaDescribedby,"name":"outline-primary","button-variant":"outline-primary","size":"sm","buttons":""},on:{"change":function($event){return _vm.courierUpdate(item)}},model:{value:(item.city),callback:function ($$v) {_vm.$set(item, "city", $$v)},expression:"item.city"}})]}}],null,true)})]}},{key:"cell(status)",fn:function(ref){
var item = ref.item;
return [_c('b-form-group',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ariaDescribedby = ref.ariaDescribedby;
return [_c('b-form-radio-group',{attrs:{"id":"btn-radios-1","options":[{text: 'вкл', value: 'ready'},{text: 'откл', value: 'notready'}],"aria-describedby":ariaDescribedby,"name":"outline-primary","button-variant":"outline-primary","size":"sm","buttons":"","disabled":!item.tgChatId},on:{"change":function($event){return _vm.courierUpdate(item)}},model:{value:(item.status),callback:function ($$v) {_vm.$set(item, "status", $$v)},expression:"item.status"}})]}}],null,true)})]}},{key:"cell(actions)",fn:function(ref){
var item = ref.item;
return [_c('b-badge',{staticClass:"mr-2",attrs:{"href":"#","size":"sm","variant":"danger"},on:{"click":function($event){$event.preventDefault();return _vm.delCourier(item)}}},[_c('b-icon-trash'),_vm._v(" удалить")],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }